(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Product', Product)
        .factory('ProductCategory', ProductCategory)
        .factory('ProductPrimeImage', ProductPrimeImage)
        .factory('DepoService', DepoService)
        .factory('ProductInventory', ProductInventory)
        .factory('ProductFlashSale', ProductFlashSale);

    Product.$inject = ['$resource', 'DateUtils'];

    function Product ($resource, DateUtils) {
        var resourceUrl =  'api/products/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.saleStartDate = DateUtils.convertDateTimeFromServer(data.saleStartDate);
                        data.saleEndDate = DateUtils.convertDateTimeFromServer(data.saleEndDate);
                        data.availableDate = DateUtils.convertDateTimeFromServer(data.availableDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getVendorProductCount': {
                method: 'GET',
                url: 'api/vendor/product-count'
            },
        });
    }

    ProductCategory.$inject = ['$resource'];

    function ProductCategory ($resource) {
        var resourceUrl =  'api/products/category';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getProductSortOrders': {
                method: 'GET',
                isArray: true,
                url: 'api/products/category/sort-orders'
            },
            'getAsList': {
                method: 'GET',
                isArray: true,
                url: 'api/products/category-list'
            }
        });
    }

    ProductPrimeImage.$inject = ['$resource'];

    function ProductPrimeImage ($resource) {
        var resourceUrl = 'api/products/:id/prime-image/:imageId';
        return $resource(resourceUrl, {"id": "@id", "imageId": "@imageId"}, {
            'update': {method: 'PUT'}
        });
    }


    ProductInventory.$inject = ['$resource'];

    function ProductInventory ($resource) {
        var resourceUrl =  'api/products/inventory';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getSummaryTotals': {
                method: 'GET',
                isArray: false,
                url: 'api/products/summary-totals'
            }
        });
    }

    ProductFlashSale.$inject = ['$resource'];

    function ProductFlashSale ($resource) {
        var resourceUrl =  'api/products/flash-sale';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }

    DepoService.$inject = ['$resource'];

    function DepoService($resource) {
        var resourceUrl = 'api/depo/v1/products/b2b-price?productId=:productId&variantId=:variantId';
        return $resource(resourceUrl, {"productId": "@productId", "variantId": "@variantId"}, {
            'query': {method: 'GET', isArray: true},
            'updateB2bPrice': {method: 'PUT', url: 'api/depo/v1/products/', headers: { 'AppName': 'bozorakaAdmin' }}
        });
    }
})();
