(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleController', FlashSaleController);

    FlashSaleController.$inject = ['$state', '$filter', '$translate', 'FlashSale', 'ParseLinks', 'AlertService', 'DateUtils', 'paginationConstants', 'pagingParams', 'Category', 'ProductFlashSale'];

    function FlashSaleController($state, $filter, $translate, FlashSale, ParseLinks, AlertService, DateUtils, paginationConstants, pagingParams, Category, ProductFlashSale) {

        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.productStatuses = ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"]
        vm.statuses = ["ACTIVE", "INACTIVE", "UPCOMING", "COMPLETED"]
        vm.parentCategoryField = pagingParams.parentCategoryField;
        vm.childCategoryField = pagingParams.childCategoryField;
        vm.childCategoryList = pagingParams.childCategoryList;
        vm.searchQuery = {
            productName: pagingParams.productName,
            sku: pagingParams.sku,
            category: pagingParams.category,
            statuses: pagingParams.statuses,
            flashSaleStatus: pagingParams.flashSaleStatus,
            parentCategories: pagingParams.parentCategories,
            childCategories: pagingParams.childCategories
        };
        vm.parentCategoryField = null;
        vm.onSelectCategory = onSelectCategory;
        vm.categories = [];
        vm.categoryTree = [];
        vm.open = open;
        vm.opened = {};
        vm.isEditing = false;
        vm.openDatepicker = openDatepicker;
        vm.saveFlashSaleStartDate = saveFlashSaleStartDate;
        vm.saveFlashSaleEndDate = saveFlashSaleEndDate;
        vm.cancelEditing = cancelEditing;
        vm.saveFlashSaleDiscountPercentage = saveFlashSaleDiscountPercentage;
        vm.saveStatus = saveStatus;
        vm.dateDiff = DateUtils.flashSaleDateDifference;

        vm.search = search;
        vm.clear = clear;
        vm.dateOptions = {
            formatYear: 'yyyy',
            startingDay: 1,
            showWeeks: false
        };

        vm.openStartTimePicker = function(flashSale, $event) {
            $event.preventDefault();
            $('#startTimePickerModal-' + flashSale.productId).modal('show');
        };

        vm.openEndTimePicker = function(flashSale, $event) {
            $event.preventDefault();
            $('#endTimePickerModal-' + flashSale.productId).modal('show');
        };

        vm.statusFilter = function(flashSale) {
            return function(status) {
                if (flashSale.status === 'ACTIVE') {
                    return status !== 'COMPLETED' && status !== 'UPCOMING';
                } else if (flashSale.status === 'UPCOMING') {
                    return status !== 'COMPLETED' && status !== 'ACTIVE';
                } else if (flashSale.status === 'COMPLETED') {
                    return status !== 'UPCOMING' && status !== 'ACTIVE';
                }
                return true;
            };
        };

        loadCategories();

        function search() {
            setCategory()
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'fs.endTime';
            vm.reverse = true;
            if (vm.searchQuery.sku !== null) {
                vm.predicate = 'fs.endTime';
            }
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'fs.endTime';
            vm.reverse = true;
            vm.parentCategoryField = null;
            vm.childCategoryField = null;
            vm.searchQuery = {
                productName: null,
                sku: null,
                category: null,
                parentCategories: null,
                childCategories: null,
                statuses: ["AVAILABLE", "OUT_OF_STOCK"],
                flashSaleStatus: "ACTIVE"
            };
            vm.childCategoryList = null;
            vm.transition();
        }

        function setCategory() {
            if (vm.parentCategoryField != null) {
                vm.searchQuery.parentCategories = [];
                vm.parentCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.parentCategories.push(category.id);
                });
            }
            if (vm.childCategoryField != null) {
                vm.searchQuery.childCategories = [];
                vm.childCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.childCategories.push(category.id);
                });
            }
        }

        function onSelectCategory() {
            var newChildCategoryList = [];
            vm.parentCategoryField.forEach(function (element) {
                var category = angular.fromJson(element.toString());
                newChildCategoryList.push.apply(newChildCategoryList, category.children);
            });
            if (vm.childCategoryList !== null && newChildCategoryList.length < vm.childCategoryList.length && vm.childCategoryField !== null) {
                vm.childCategoryList = vm.childCategoryList.filter(function(element) {
                    return !newChildCategoryList.some(function (child){ return (child.id === element.id)});
                });
                vm.childCategoryField = vm.childCategoryField.filter(function(element) {
                    element = angular.fromJson(element.toString());
                    return !vm.childCategoryList.some(function (child){ return (child.id === element.id)});
                });
            }
            vm.childCategoryList = newChildCategoryList;
            setTimeout(function () {
                $('#field_child_category').multiselect(
                    'setOptions',
                    {
                        selectAllText: all,
                        nonSelectedText: noneSelected,
                        allSelectedText: allSelected
                    });
                $('#field_child_category').multiselect('rebuild');
            }, 0);
        }
        loadAll();

        function loadAll () {
            ProductFlashSale.query({
                productName: vm.searchQuery.productName === null ? '' : vm.searchQuery.productName,
                sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                category: vm.searchQuery.category,
                statuses: vm.searchQuery.statuses,
                flashSaleStatus: vm.searchQuery.flashSaleStatus,
                parentCategories: vm.searchQuery.parentCategories,
                childCategories: vm.searchQuery.childCategories,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.flashSales = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                productName: vm.searchQuery.productName === null ? '' : vm.searchQuery.productName,
                sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                category: vm.searchQuery.category,
                statuses: vm.searchQuery.statuses,
                flashSaleStatus: vm.searchQuery.flashSaleStatus,
                parentCategoryField: vm.parentCategoryField,
                childCategoryField: vm.childCategoryField,
                parentCategories: vm.searchQuery.parentCategories,
                childCategories: vm.searchQuery.childCategories,
                childCategoryList: vm.childCategoryList
            });
        }

        function loadCategories() {
            Category.getAllCategories({}, function (data) {
                vm.categories = data;
                translateCategoryName();
                vm.categories.sort(function (a, b) {
                    return a.parentId > b.parentId ? 1 : -1;
                });
                for (var j = 0; j < vm.categories.length; j++) {
                    if (vm.categories[j].parentId === null) {
                        vm.categoryTree.push(vm.categories[j]);
                    } else {
                        var index = vm.categoryTree.findIndex(function (a) {
                            return a.id === vm.categories[j].parentId;
                        });
                        if (index > -1) {
                            if (vm.categoryTree[index].children === undefined) {
                                vm.categoryTree[index].children = [];
                            }
                            vm.categoryTree[index].children.push(vm.categories[j]);
                        }

                    }
                }
                setTimeout(function () {
                    $('#field_parent_category').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_parent_category').multiselect('rebuild');
                }, 0);
            });
        }

        function translateCategoryName() {
            var currentLang = $translate.use();
            for (var i = 0; i < vm.categories.length; i++) {
                if (currentLang === 'en') {
                    vm.categories[i]['name'] = vm.categories[i]['nameEng'];
                } else {
                    vm.categories[i]['name'] = vm.categories[i]['nameUzb'];
                }
            }
        }

        function open($event, elementOpened){
            $event.preventDefault();
            $event.stopPropagation();

            vm.opened[elementOpened] = !vm.opened[elementOpened];
        }

        function saveFlashSaleStartDate(flashSale) {
                $('#startTimePickerModal-' + flashSale.productId).modal('hide');
                var productFlashSale = {
                    "startTime": flashSale.startTime,
                    "endTime": flashSale.endTime,
                    "discountPercentage": flashSale.discountPercentage,
                    "status": flashSale.status,
                    "visibleState": "ACTIVE",
                    "createdBy": flashSale.createdBy,
                    "createdDate": flashSale.createdDate,
                    "lastModifiedBy": flashSale.lastModifiedBy,
                    "lastModifiedDate": flashSale.lastModifiedDate,
                    "productId": flashSale.productId,
                    "id": flashSale.id
                }
            if (flashSale.startTime != null && flashSale.endTime != null && flashSale.discountPercentage != null) {
                if (flashSale.status == null) {
                    productFlashSale.status = "UPCOMING";
                }
                FlashSale.update(productFlashSale, onSaveSuccess, onSaveError);
            }
        }

        function saveFlashSaleEndDate(flashSale) {
            $('#endTimePickerModal-' + flashSale.productId).modal('hide');
            if(flashSale.startTime > flashSale.endTime){
                flashSale.startTime = flashSale.endTime;
            } else {
                var productFlashSale = {
                    "startTime": flashSale.startTime,
                    "endTime": flashSale.endTime,
                    "discountPercentage": flashSale.discountPercentage,
                    "status": flashSale.status,
                    "visibleState": "ACTIVE",
                    "createdBy": flashSale.createdBy,
                    "createdDate": flashSale.createdDate,
                    "lastModifiedBy": flashSale.lastModifiedBy,
                    "lastModifiedDate": flashSale.lastModifiedDate,
                    "productId": flashSale.productId,
                    "id": flashSale.id
                }
            }
            if(flashSale.startTime != null && flashSale.endTime != null && flashSale.discountPercentage != null){
                if(flashSale.status ==null) {
                    productFlashSale.status = "UPCOMING";
                }
                FlashSale.update(productFlashSale, onSaveSuccess, onSaveError);
            }
        }

        function openDatepicker(event) {
            event.preventDefault();
            event.stopPropagation();
            vm.isDateOpen = true;
        }

         function cancelEditing() {
            vm.isEditing = false;
        }

        function saveFlashSaleDiscountPercentage(flashSale) {
            var productFlashSale = {
                "startTime": flashSale.startTime,
                "endTime": flashSale.endTime,
                "discountPercentage": flashSale.discountPercentage,
                "status": flashSale.status,
                "visibleState": "ACTIVE",
                "createdBy": flashSale.createdBy,
                "createdDate": flashSale.createdDate,
                "lastModifiedBy": flashSale.lastModifiedBy,
                "lastModifiedDate": flashSale.lastModifiedDate,
                "productId": flashSale.productId,
                "id": flashSale.id
            }
            if(flashSale.startTime != null && flashSale.endTime != null && flashSale.discountPercentage != null){
                if(flashSale.status ==null) {
                    productFlashSale.status = "UPCOMING";
                }
                FlashSale.update(productFlashSale, onSaveSuccess, onSaveError);
            }
        }

        function saveStatus(flashSale) {
            var productFlashSale = {
                "startTime": flashSale.startTime,
                "endTime": flashSale.endTime,
                "discountPercentage": flashSale.discountPercentage,
                "status": flashSale.status,
                "visibleState": "ACTIVE",
                "createdBy": flashSale.createdBy,
                "createdDate": flashSale.createdDate,
                "lastModifiedBy": flashSale.lastModifiedBy,
                "lastModifiedDate": flashSale.lastModifiedDate,
                "productId": flashSale.productId,
                "id": flashSale.id
            }
            if(flashSale.startTime != null && flashSale.endTime != null && flashSale.discountPercentage != null){
                FlashSale.update(productFlashSale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess() {
            loadAll();
        }

        function onSaveError() {
            loadAll();
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#productStatus').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected')
            });

            $('#field_parent_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

            $('#field_child_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('product.all');
            noneSelected = translate('product.noneSelected');
            allSelected = translate('product.allSelected');
        }
    }
})();
