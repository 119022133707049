(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('FlashSale', FlashSale)
        .factory('FlashSaleHistory', FlashSaleHistory);

    FlashSale.$inject = ['$resource', 'DateUtils'];

    function FlashSale ($resource, DateUtils) {
        var resourceUrl =  'api/flash-sales/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save':{ method: 'PUT' }
        });
    }

    FlashSaleHistory.$inject = ['$resource'];

    function FlashSaleHistory($resource) {
        var resourceUrl = 'api/products/:id/flash-sale-histories';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET'}
        });
    }
})();
