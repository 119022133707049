(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleHistoryController', FlashSaleHistoryController);

    FlashSaleHistoryController.$inject = ['$state', 'FlashSaleHistory', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$stateParams'];

    function FlashSaleHistoryController($state, FlashSaleHistory, ParseLinks, AlertService, paginationConstants, pagingParams, $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.search = search;
        vm.clear = clear;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.productId = $stateParams.id;
        vm.variantId = $stateParams.variantId;
        vm.name = $stateParams.name;
        vm.variantName = $stateParams.variantName;
        vm.sku = $stateParams.sku;
        vm.totalSold = 0;
        vm.searchQuery = {
            valueLong1: vm.variantId,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };
        vm.loadAll = loadAll;
        vm.hasVariantName = hasVariantName;
        vm.hasSku = hasSku;

        initDatePicker();
        loadAll();

        function loadAll() {
            FlashSaleHistory.query({
                id: vm.productId,
                valueLong1: vm.variantId == null ? -1 : vm.variantId,
                fromDate: vm.searchQuery.fromDate === '' ? null : vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate === '' ? null : vm.searchQuery.toDate,
                variantId: vm.variantId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.flashSaleHistories = data.flashSaleHistories;
                vm.totalSold = data.totalSold;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function hasVariantName() {
            return vm.variantName !== null && vm.variantName !== undefined && vm.variantName !== "";
        }

        function hasSku() {
            return vm.sku !== null && vm.sku !== undefined && vm.sku !== "";
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.productId,
                valueLong1: vm.variantId == null ? -1 : vm.variantId,
                fromDate: vm.searchQuery.fromDate === '' ? null : vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate === '' ? null : vm.searchQuery.toDate,
                variantId: vm.variantId,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                name: vm.name,
                variantName: vm.variantName,
                sku: vm.sku
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.variantId = $stateParams.variantId;
            vm.searchQuery = {
                valueLong1: vm.variantId,
                fromDate: null,
                toDate: null
            };
            initDatePicker();
            vm.transition();
        }
    }
})();
